import { SyncLoader } from "react-spinners";
import "../../styles/pages/Global/Loading.scss";
type Props = {};

const LoadingComponent = (props: Props) => {
  return (
    <div className="loading">
      <SyncLoader color="#024ec7" size={8} />
    </div>
  );
};

export default LoadingComponent;
